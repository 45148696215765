<template>
  <!-- navigation START -->
  <div id="nav">
    <div class="main-navigation">
      <!-- logo START -->
      <div class="logo logo-centered">
        <router-link :to="'/'">
          <img
            width="222"
            height=""
            src="@/assets/images/logo-mpreis.png"
            v-if="$route.name !== 'screensaver'"
          />
          <img width="222" height="" src="@/assets/images/logo-mpreis.png" v-else />
        </router-link>
      </div>
      <!-- logo END -->
    </div>
  </div>
  <!-- navigation END -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
